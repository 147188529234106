import { Summary } from '../components/dashboard'
import { apiCall } from '../utils/api'
export type GetSummaryRequest = {
   loyaltyProgramId: number
   from?: string
   to?: string
}
type Member = {
   id: string
   firstName: string
   lastName: string
   email: string
   externalId: number
   fullName: string
}
interface UserProps<T> {
   member: Member
   extraField: T
}
type UserWithDate = UserProps<{ date: string }>
type UserWithTotalValue = UserProps<{ totalValue: number }>

export async function getSummary(request: GetSummaryRequest): Promise<Summary | null> {
   const { loyaltyProgramId } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/plans/${loyaltyProgramId}/summary`,
      method: 'GET',
      params: {
         from: request.from,
         to: request.to,
      },
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function getMembersMostIncome(id?:number): Promise<Array<UserWithTotalValue> | null> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/top-by-reservations?loyaltyPlanId=${id}`,
      method: 'GET',
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function getMembersMostPoints(id?:number): Promise<Array<UserWithTotalValue> | null> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/top-by-points?loyaltyPlanId=${id}`,
      method: 'GET',
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function getMembersMostActivity(id?:number): Promise<Array<UserWithDate> | null> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/top-by-recent-transactions?loyaltyPlanId=${id}`,
      method: 'GET',
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function getMembersLessActivity(id?:number): Promise<Array<UserWithDate> | null> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/top-by-less-activity?loyaltyPlanId=${id}`,
      method: 'GET',
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
