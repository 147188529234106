import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import 'ui/styles/form.css'
import 'ui/styles/buttons.css'
import 'react-widgets/styles.css'
import { Partners } from './components/partners'
import { Layout } from './components/Layout'
import { Operations } from './components/operations'
import { Programs } from './components/programs'
import { useEffect } from 'react'
import PrivateRoute from './components/common/PrivateRoute'
import AccountRoute from './components/common/AccountRoute'
import { Dashboard } from './components/dashboard'
import { NotFound } from './components/NotFound'
import { Accounts } from './components/accounts'
import Partner from './components/partners/Partner'
import Program from './components/programs/Program'
import AddParter from './components/partners/components/AddPartner'
import { useAppDispatch } from './store/hooks'
import { loadLocale } from './features/baseSlice'
import ImportUsers from './components/partners/components/ImportUsers'
import ImportOperations from './components/operations/components/ImportOperations'
import EditPartner from './components/partners/components/EditPartner'
import ImportPMSOperation from './components/operations/components/ImportPMSOperation'
import PartnerOperations from './components/partners/PartnerOperations'
import OperationDetails from './components/partners/components/operations/OperationDetails'
import EditPartnerOperation from './components/partners/components/operations/EditOperation'
import CreatePartnetOperation from './components/partners/components/operations/CreatePartnetOperation'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/zoom.css'

type Props = {
   locale: string
}

function App({ locale }: Props) {
   const dispatch = useAppDispatch()
   useEffect(() => {
      if (locale) {
         dispatch(loadLocale(locale))
      }
   }, [dispatch, locale])
   return (
      <>
         <Routes>
            <Route element={<Layout />}>
               <Route path="/" element={<Navigate to="/accounts" />} />
               <Route path="/:accountCode" element={<Navigate to="/accounts" />} />
               <Route path="/accounts" element={<PrivateRoute element={<Accounts />} />} />
               <Route
                  path="/:accountCode/dashboard"
                  element={<PrivateRoute element={<AccountRoute element={<Dashboard />} />} />}
               />
               <Route
                  path="/:accountCode/partners"
                  element={<PrivateRoute element={<AccountRoute element={<Outlet />} />} />}
               >
                  <Route index element={<Partners />} />
                  <Route path="create" element={<AddParter />} />
                  <Route path="import" element={<ImportUsers />} />
                  <Route path=":id" element={<Partner />} />
                  <Route path=":id/operations" element={<PartnerOperations />} />
                  <Route path=":id/edit" element={<EditPartner />} />
                  <Route path=":id/operation/:operationId" element={<OperationDetails />} />
                  <Route path=":id/operation/create" element={<CreatePartnetOperation />} />
                  <Route path=":id/operation/:operationId/edit" element={<EditPartnerOperation />} />
               </Route>
               <Route
                  path="/:accountCode/programs"
                  element={<PrivateRoute element={<AccountRoute element={<Outlet />} />} />}
               >
                  <Route index element={<Programs />} />
                  <Route path=":id" element={<Program />} />
               </Route>
               <Route
                  path="/:accountCode/operations"
                  element={<PrivateRoute element={<AccountRoute element={<Outlet />} />} />}
               >
                  <Route index element={<Operations />} />
                  <Route path="import" element={<ImportOperations />} />
                  <Route path="importPms" element={<ImportPMSOperation />} />
               </Route>
               {/* Ruta 404 */}
               <Route path="*" element={<NotFound />} />
            </Route>
         </Routes>
      </>
   )
}

export default App
