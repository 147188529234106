import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useState } from 'react'

import useGetPartner from '../../../hooks/useGetPartner'
import Title from '../../common/Title'
import ErrorPage from '../../common/ErrorPage'
import useGetChannelList from '../../../hooks/useGetChannelList'
import useGetPrograms from '../../../hooks/useGetPrograms'
import { editPartner } from '../../../api/partner'

import Forms from './forms/Forms'

function EditPartner() {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const [loading, setLoading] = useState(false)
   const { data: partner, error: partnerError } = useGetPartner(id)
   const { data: channels, error: channelListError } = useGetChannelList()
   const { data: plans, error: programsError } = useGetPrograms()
   const { mutate: submitForm } = useMutation({
      mutationFn: editPartner,
   })
   const navigate = useNavigate()
   const onSubmit = (data: any) => {
      const { loyaltyPlans, swimmingSkill, ...rest } = data
      setLoading(true)
      submitForm(
         {
            ...rest,
            plans: loyaltyPlans.map((plan: any) => ({
               id: plan.id,
               loyaltyPlanId: plan.loyaltyPlanId,
               registrationChannel: plan.registrationChannel,
               levelName: plan.level,
            })),
            ...(swimmingSkill && { swimmingSkill: swimmingSkill === 'Si' }),
            urlRefer: '',
         },
         {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: 'partner.edit.success' }))
               navigate(`/${accountCode}/partners`)
            },
            onError: (error: any) => {
               toast.error(
                  error?.response?.data?.error
                     ? error.response.data.error
                     : intl.formatMessage({ id: 'partner.edit.fail' }),
               )
               setLoading(false)
            },
         },
      )
   }
   const onCancel = () => {
      navigate(`/${accountCode}/partners/${id}`)
   }

   if (channelListError || programsError || partnerError) {
      return <ErrorPage />
   }

   if (!partner || !channels || !plans) {
      return (
         <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
         </div>
      )
   }

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         url: `/${accountCode}/partners/${partner.id}`,
         label: partner.externalId.toString(),
      },
      {
         label: intl.formatMessage({ id: 'partner.edit' }),
      },
   ]

   return (
      <>
         <Title breadcrumb={breadcrumb} value={intl.formatMessage({ id: 'partner.edit' })}></Title>
         <Forms
            isloading={loading}
            defautlValues={(partner as any) || undefined}
            onSubmit={onSubmit}
            onCancel={onCancel}
            channels={channels}
            plans={plans}
         />
      </>
   )
}

export default EditPartner
