import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getMembersLessActivity } from '../../api/summary'

function useGetMembersLessActivity(id?: number) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_MEMBERS_LESS_ACTIVITY],
      queryFn: () => getMembersLessActivity(id),
      enabled: !!id,
   })
}
export { useGetMembersLessActivity }
export default useGetMembersLessActivity
