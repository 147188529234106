//https://unicode-org.github.io/icu/userguide/format_parse/datetime/#datetime-format-syntax
import { DateObjectUnits, DateTime, DateTimeFormatOptions, DurationUnits } from 'luxon'

export type DateType = DateTime

const defaultFormat = 'yyyy-MM-dd'

export function today() {
   return DateTime.now()
}

export function createDate(date?: string | Date | Array<number>, format = defaultFormat) {
   if (!date || !isDate(date, format)) {
      return null
   }

   if (typeof date === 'string') {
      return DateTime.fromFormat(date, format)
   } else if (Array.isArray(date)) {
      if (date.length >= 3) {
         return DateTime.local(date[0], date[1], date[2])
      }
   } else {
      return DateTime.fromJSDate(date)
   }
   return null
}

export function isDate(date?: string | Date | Array<number>, format = defaultFormat) {
   if (!date) {
      return null
   }
   if (typeof date === 'string') {
      return DateTime.fromFormat(date, format).isValid
   } else if (Array.isArray(date)) {
      if (date.length >= 3) {
         return DateTime.local(date[0], date[1], date[2]).isValid
      }
   } else {
      return DateTime.fromJSDate(date).isValid
   }
   return null
}

export function getNights(startDate?: string, endDate?: string, format = defaultFormat) {
   const checkIn = createDate(startDate, format)
   const checkOut = createDate(endDate, format)
   if (checkIn && checkOut) {
      return Math.abs(checkIn.diff(checkOut, 'days').days)
   } else {
      return 0
   }
}

export function getFormattedDate(date: string | DateTime | Array<number>, format: string | DateTimeFormatOptions, locale: string, inputFormat: string = defaultFormat): string | null {
   let result: DateTime | null = null;
   if (typeof date === 'string' || Array.isArray(date)) {
      result = createDate(date, inputFormat)
   } else {
      result = date
   }
   if (result) {
      if (typeof format === 'string') {
         return result.setLocale(locale).toFormat(format)
      } else {
         return result.setLocale(locale).toLocaleString(format)
      }
   }

   return null
}

export function getMinDate(dates: Array<DateTime | string>) {
   const filteredDates = dates.map(d => typeof d === 'string' ? createDate(d) : d).filter(s => s) as Array<DateTime>
   return DateTime.min(...filteredDates);
}

export function getMaxDate(dates: Array<DateTime | string>) {
   const filteredDates = dates.map(d => typeof d === 'string' ? createDate(d) : d).filter(s => s) as Array<DateTime>
   return DateTime.max(...filteredDates);
}

export function createDateFromISOAndZone(date: string, zone: number) {
   return DateTime.fromISO(date)
}

export function createDateFromISO(date: string, options?: { setZone: boolean }) {
   return DateTime.fromISO(date, options ? { ...options.setZone && { setZone: options.setZone } } : undefined)
}

export function createDateFromObject(object: DateObjectUnits) {
   return DateTime.fromObject(object)
}

export function getDiff(date1: string, date2: string, unit: DurationUnits) {
   const objDate1 = createDate(date1)
   const objDate2 = createDate(date2)
   if (objDate1 && objDate2) {
      return objDate1.diff(objDate2, unit).toObject()
   }
   return null
}
