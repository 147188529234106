import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetChannelList } from '../../../hooks/useGetChannelList'
import useGetPrograms from '../../../hooks/useGetPrograms'
import { createPartner } from '../../../api/partner'
import ErrorPage from '../../common/ErrorPage'
import Forms, { Inputs } from './forms/Forms'

type Props = {
   defautlValues?: Inputs
   onSubmit?: (data: Inputs) => void
}
const AddParter = (props: Props) => {
   const intl = useIntl()
   const { accountCode } = useParams()
   const [loading, setLoading] = useState(false)
   const { data: channels,error: channelListError } = useGetChannelList()
   const { data: plans, error: programsError } = useGetPrograms()
   const navigate = useNavigate()
   const { mutate: submitForm } = useMutation({
      mutationFn: createPartner,
   })
   const onSubmit = (data: Inputs) => {
      const { loyaltyPlans: plans, swimmingSkill, ...rest } = data

      let request = {
         ...rest,
         plans: plans.map((plan) => ({
            ...plan,
            levelName: plan.level,
         })),
         ...(swimmingSkill && { swimmingSkill: swimmingSkill === 'Si' }),
      }

      submitForm(request, {
         onSuccess: () => {
            toast.success(intl.formatMessage({ id: 'partner.create.success' }))
            navigate(`/${accountCode}/partners`)
         },
         onError: (error: any) => {
            toast.error(
               error?.response?.data?.error
                  ? error.response.data.error
                  : intl.formatMessage({ id: 'partner.fail.error' }),
            )
            setLoading(false)
         },
      })
   }

   const onCancel = () => {
      navigate(`/${accountCode}/partners`)
   }

   if (channelListError || programsError) {
      return <ErrorPage />
   }

   if (!channels || !plans) {
      return (
         <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
         </div>
      )
   }

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         label: intl.formatMessage({ id: 'partner.create.tittle' }),
      },
   ]

   return (
      <>
         <Title breadcrumb={breadcrumb} value={intl.formatMessage({ id: 'partner.create.tittle' })} />
         <Forms onSubmit={onSubmit} onCancel={onCancel} channels={channels} plans={plans} isloading={loading} />
      </>
   )
}

export default AddParter
