import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'

import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { TextSpinner } from 'ui'
import Card from '../../common/Card'
import { useGetPartnerHistory } from '../../../hooks/useGetPartnerHistory'
import { DateTime } from 'luxon'
export type HistoryItem = {
   pointsAccumulated?: number | null
   pointsRedeemed?: number | null
   hotelName: string
   service: string
   id: string
   createdAt: string
   author?: string
   details: string
   type: string
}

type Props = {
   idPlan: number | undefined
}

const History = (props: Props) => {
   const { idPlan } = props
   const intl = useIntl()
   const { accountCode, id } = useParams()
   const { data: history, status } = useGetPartnerHistory({ memberId: id, size: 10, page: 0 })

   const getRelativeDate = (date: string) => {
      const fecha = DateTime.fromISO(date);
      return fecha?.toRelative();
   }

   return (
      <Card>
         <Card.Title>
            <div className="flex justify-between aling-center">{intl.formatMessage({ id: 'partner.history' })}</div>
         </Card.Title>
         {status === 'pending' ? (
            Array.from({ length: 4 }, (_, i) => (
               <div className="p-4" key={i}>
                  <div className="flex gap-2">
                     <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('mt-1.5 text-neutral-400')} />
                     <div className="w-full">
                        <TextSpinner className="mb-2" />
                        <TextSpinner />
                     </div>
                  </div>
               </div>
            ))
         ) : (
            <>
               <div
                  className={classNames('overflow-auto', {
                     'border-neutral-200': history?.items.length === 0,
                     'h-[545px]': history && history?.items.length > 15,
                  })}
               >
                  {history?.items.map((item, index) => {
                     const { pointsAccumulated = 0, pointsRedeemed = 0, author, details, type } = item
                     return (
                        <div className="p-4" key={index}>
                           <div className="flex gap-2">
                              <FontAwesomeIcon
                                 icon={faCircle}
                                 size="xs"
                                 className={classNames('mt-1.5', {
                                    'text-green-500': pointsAccumulated && pointsAccumulated > 0,
                                    'text-blue-500': (pointsRedeemed && pointsRedeemed > 0) || type === 'MEMBER_ACTIVATE',
                                    'text-red-500': type === 'MEMBER_DEACTIVATE',

                                 })}
                              />
                              {(pointsAccumulated || pointsRedeemed) ?
                                 <Link to={`/${accountCode}/operations?query=${item.id}`}>
                                    <FormattedMessage
                                       id="partner.historyItem"
                                       values={{
                                          name: item.hotelName,
                                          service: item.service,
                                          points:
                                             pointsAccumulated && pointsAccumulated > 0 ? (
                                                <strong>
                                                   {intl.formatMessage(
                                                      { id: 'partner.points.accumulated' },
                                                      { points: item.pointsAccumulated },
                                                   )}
                                                </strong>
                                             ) : (
                                                <strong>
                                                   {intl.formatMessage(
                                                      { id: 'partner.points.redeemed' },
                                                      { points: item.pointsRedeemed },
                                                   )}
                                                </strong>
                                             ),
                                       }}
                                    /></Link> :
                                 <div>
                                    <FormattedMessage
                                       id="partner.historyItem.status"
                                       values={{
                                          author: item.author,
                                          status: author && details ?
                                             <strong>{intl.formatMessage({ id: 'partner.historyItem.status.activate' })}</strong> :
                                             <strong>
                                                {intl.formatMessage(
                                                   { id: 'partner.historyItem.status.deactivate' },
                                                )}
                                             </strong>,
                                       }} />

                                    {item.details && <p className='text-neutral-500 p-3 border-l-2 block'>{item.details}</p>}
                                 </div>

                              }

                           </div>
                           <p className='text-neutral-400 ml-4'>
                              <FontAwesomeIcon
                                 icon={faClock}
                                 size="xs"
                                 className={classNames('mt-2', 'mr-1')}
                              />
                              {getRelativeDate(item.createdAt)}
                           </p>
                        </div>
                     )
                  })}
               </div>
               <div className="p-4 border-t">
                  <Link className="text-blue-500" to={`/${accountCode}/operations?query=${idPlan || id}`}>
                     {intl.formatMessage({ id: 'partner.all.operations' })}
                  </Link>
               </div>
            </>
         )}
      </Card>
   )
}

export default History
