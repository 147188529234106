import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperationTypeList } from '../../api/operation'

function useGetOperationTypeList() {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATION_TYPE_LIST],
      queryFn: () => {
         return getOperationTypeList()
      },
   })
}
export { useGetOperationTypeList }
export default useGetOperationTypeList
