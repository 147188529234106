import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getMembersMostIncome } from '../../api/summary'

function useGetMembersMostIncome(id: number) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_MEMBERS_MOST_INCOME],
      queryFn: () => getMembersMostIncome(id),
      enabled: !!id,
      
   })
}
export { useGetMembersMostIncome }
export default useGetMembersMostIncome
