import { faDownload, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { Button, Table } from 'ui'
import { useGetPartnerList } from '../../../hooks/useGetPartnerList'
// import { useNavigate } from 'react-router-dom'
type Props = {
   currentValues: any
   onPageChange: (p: any) => any
   onSortChange: (p: any) => any
   onExport: (type: string) => any
   columns: any
   isExporting?: boolean
}

function TablePartners(props: Props) {
   const { currentValues, onPageChange, columns, onSortChange, onExport, isExporting } = props
   const { sort } = currentValues
   // const navigate = useNavigate()
   const { data: partnerList, status } = useGetPartnerList(currentValues)
   const intl = useIntl()
   // const onImport = () => {
   //    navigate(`import`)
   // }
   return (
      <>
         <Table
            fetching={status === 'pending'}
            columns={columns}
            data={partnerList?.items ?? []}
            tableClassName="border-y"
            footerClassName="px-4"
            showTableResume
            pagination={{
               defaultPageSize: currentValues.size,
               defaultPageIndex: currentValues.page,
               totalSize: partnerList?.totalSize ?? 0,
               onPageChange: onPageChange,
            }}
            sorting={{
               sorting:
                  sort && sort.length
                     ? [{ id: sort?.split(',')[0], desc: sort?.split(',')[1] === 'desc' }]
                     : null,
               onSortChange: onSortChange,
            }}
         >
            <div className="flex justify-between gap-x-2">
               {/* <Button onClick={onImport} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'partner.import' })}
               </Button> */}
               <Button
                  disabled={isExporting}
                  onClick={() => onExport('BASIC')}
                  className={'flex gap-x-2 items-center text-[12px]'}
               >
                  <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'partner.export.basic' })}
               </Button>
               <Button
                  disabled={isExporting}
                  onClick={() => onExport('FULL')}
                  className={'flex gap-x-2 items-center text-[12px]'}
               >
                  <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'partner.export.advanced' })}
               </Button>
            </div>
         </Table>
      </>
   )
}

export default TablePartners
