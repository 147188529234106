import React from 'react'
import { useIntl } from 'react-intl'
import { Amount, TextSpinner } from 'ui'
import { useGetOperationsResume } from '../../../hooks/useGetOperationsResume'
type Props = {
   request: {
      loyaltyPlanId?: number
      memberId?: string
      dates?: {
         from?: string
         to?: string
      }
      operationStatus?: Array<string>
      channelIds?: Array<number>
      query?: string
   }
}

function ResumeOperations(props: Props) {
   const { request } = props
   const intl = useIntl()
   const { data: operationsResume } = useGetOperationsResume(request)
   return (
      <>
         <div className="md:flex gap-x-8 space-y-4 md:space-y-0 mx-4 py-4 px-6 mb-8 border rounded justify-between">
            <>
               <div className="overflow-x-auto">
                  <div className=" truncate">{intl.formatMessage({ id: 'operations.activations.dashboard' })}</div>
                  {operationsResume && operationsResume.activations ? (
                     <Amount
                        amount={operationsResume.activations}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
               <div className="overflow-x-auto">
                  <div className=" truncate">{intl.formatMessage({ id: 'operations.pointsAvailable' })}</div>
                  {operationsResume && operationsResume.pointsAvailable ? (
                     <Amount
                        amount={operationsResume.pointsAvailable}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
               <div>
                  <div className=" truncate">{intl.formatMessage({ id: 'operations.pointsAccumulated' })}</div>
                  {operationsResume && operationsResume.pointsAccumulated ? (
                     <Amount
                        amount={operationsResume.pointsAccumulated}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
               <div>
                  <div className=" truncate">{intl.formatMessage({ id: 'operations.pointsRedeemed' })}</div>
                  {operationsResume && operationsResume.pointsRedeemed ? (
                     <Amount
                        amount={operationsResume.pointsRedeemed}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
               <div className="overflow-x-auto">
                  <div className=" truncate">{intl.formatMessage({ id: 'operations.totalAmount' })}</div>

                  {operationsResume && operationsResume.totalAmount ? (
                     <Amount
                        amount={operationsResume.totalAmount}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
               <div>
                  <div className="truncate">{intl.formatMessage({ id: 'operations.roomNights-dashboard' })}</div>

                  {operationsResume && operationsResume.roomNights ? (
                     <Amount
                        amount={operationsResume.roomNights}
                        showCurrency={false}
                        showCurrencySign={false}
                        className="text-gray-500 font-bold"
                     />
                  ) : (
                     <TextSpinner style={{ color: 'transparent' }}>.</TextSpinner>
                  )}
               </div>
            </>
         </div>
      </>
   )
}

export default ResumeOperations
