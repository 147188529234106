import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getMembersMostPoints } from '../../api/summary'

function useGetMembersMostPoints(id?: number) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_MEMBERS_MOST_POINTS],
      queryFn: () => getMembersMostPoints(id),
      enabled: !!id,
   })
}
export { useGetMembersMostPoints }
export default useGetMembersMostPoints
