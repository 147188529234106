import React from 'react'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button, Dropdown } from 'ui'
import { Input } from 'ui/fields'
import DateTypeSelector from '../../common/DateTypeSelector'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'
import { useGetOperationStatus } from '../../../hooks/useGetOperationStatus'
import MultiCheck from 'ui/MultiCheck/MultiCheck'
import useGetChannelList from '../../../hooks/useGetChannelList'
import { useGetOperationTypeList } from '../../../hooks/useGetOperationTypeList'
// import useGetChannelList from '../../../hooks/useGetChannelList'

export type OperationFilterForm = {
   query: string
   dates?: {
      startDate: string
      endDate: string
      dateType: string
   }
   loyaltyPlanIds: number | string
   operationStatus: Array<string>
   channelIds: Array<number>
   operationType: Array<string>
}

type Props = {
   className?: string
   onSubmit: (data: {
      query: string
      dates?: {
         startDate: string
         endDate: string
         dateType: string
      }
      loyaltyPlanIds: number | string
      operationStatus: Array<string>
      channelIds: Array<number>
      operationType: Array<string>
   }) => void
   defaultValues?: {
      query?: string
      from?: string
      to?: string
      loyaltyPlanIds: number | string
      operationStatus?: Array<string>
      channelIds: Array<number>
      operationType: Array<string>
   }
   defaultProgram?: number
}

const Filters = ({ className, onSubmit, defaultValues, defaultProgram }: Props) => {
   const { handleSubmit, register, watch, control, resetField } = useForm<OperationFilterForm>({
      defaultValues: {
         query: defaultValues?.query || '',
         loyaltyPlanIds: defaultValues?.loyaltyPlanIds || defaultProgram,
         channelIds: defaultValues?.channelIds || [],
         dates: {
            startDate: defaultValues?.from,
            endDate: defaultValues?.to,
            dateType: defaultValues?.from && defaultValues?.to ? 'other' : 'any',
         },
         operationStatus: defaultValues?.operationStatus || [],
         operationType: defaultValues?.operationType || [],
      },
      mode: 'onTouched',
   })
   // const { data: channels, status: channelsStatus } = useGetChannelList()

   const intl = useIntl()
   const formButton = React.useRef<HTMLFormElement>(null)
   const { data: programs } = useGetProgramFilter()
   const { data: operationStatus, status } = useGetOperationStatus({})
   const { data: channels, status: channelsStatus } = useGetChannelList()
   const { data: operationType, status: operationtypeStatus } = useGetOperationTypeList()
   let programId = useWatch({ control, name: 'loyaltyPlanIds' })
   const planLoyalty = programs?.items.find((program) => {
      return typeof programId === 'string' ? program.id === parseInt(programId) : program.id === programId
   })?.name
   const onSubmitEvent = (data: OperationFilterForm) => {
      const { query, ...rest } = data
      const result = { ...rest } as OperationFilterForm
      if (query && query.trim() !== '') {
         result.query = query.trim()
         result.query = encodeURIComponent(result.query)
      }

      if (result.dates?.dateType === 'any' || !result.dates?.startDate) {
         result.dates = undefined
      }
      onSubmit(result)
   }

   const drawDates = () => {
      const dates = watch('dates')
      if (dates && dates.dateType === 'other' && dates.startDate && dates.endDate) {
         return (
            <>
               {intl.formatMessage({ id: 'operations.dates' })}: {`${dates.startDate} - ${dates.endDate}`}
            </>
         )
      }

      return (
         <>
            {intl.formatMessage({ id: 'operations.dates' })}: {intl.formatMessage({ id: 'operations.any' })}
         </>
      )
   }
   const drawCount = (sizeSelected: number, total: number, fetching: boolean, word = 'Todas') => {
      if (fetching) {
         return '...'
      }
      if (sizeSelected > 0) {
         return `${sizeSelected} de ${total}`
      }
      return word
   }

   return (
      <div className={classNames(className, 'mb-6')}>
         <form onSubmit={handleSubmit(onSubmitEvent)} ref={formButton}>
            <div className="flex flex-col-reverse md:flex-row gap-y-2 justify-between md:items-center">
               <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                        {intl.formatMessage({ id: 'partners.status' })}:{' '}
                        {drawCount(
                           watch('operationStatus')?.length,
                           operationStatus?.length ?? 0,
                           status === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="operationStatus"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {operationStatus?.map((option) => (
                                       <li key={option.value} className="hover:bg-gray-100">
                                          <MultiCheck.Check
                                             key={option.value}
                                             value={option.value}
                                             label={option.label}
                                          />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                        {intl.formatMessage({ id: 'partners.program' })}: {planLoyalty}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="loyaltyPlanIds"
                              render={({ field: { ref, onChange, ...rest } }) => (
                                 <>
                                    {programs?.items?.map((option) => {
                                       if (!option.hasPointBenefit) {
                                          return null
                                       }
                                       return (
                                          <li
                                             key={option.id}
                                             className="hover:bg-gray-100 focus:outline-none cursor-pointer block  whitespace-nowrap font-light p-2"
                                             onClick={({ currentTarget }) => {
                                                onChange(option.id)
                                                formButton.current?.click()
                                             }}
                                          >
                                             {option.name}
                                          </li>
                                       )
                                    })}
                                 </>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {intl.formatMessage({ id: 'partners.filter.operationType' })}:{' '}
                        {drawCount(
                           watch('operationType')?.length,
                           operationType?.length ?? 0,
                           operationtypeStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="operationType"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {operationType?.map((option) => (
                                       <li key={option.value} className="hover:bg-gray-100">
                                          <MultiCheck.Check value={option.value} label={option.description} />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {intl.formatMessage({ id: 'partners.channel' })}:{' '}
                        {drawCount(
                           watch('channelIds')?.length,
                           channels?.items?.length ?? 0,
                           channelsStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="channelIds"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {channels?.items.map((option) => (
                                       <li key={option.id} className="hover:bg-gray-100">
                                          <MultiCheck.Check value={option.id} label={option.name} />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {drawDates()}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="dates"
                              rules={{ required: true }}
                              render={({ field: { ref, ...rest } }) => (
                                 <DateTypeSelector
                                    {...rest}
                                    placeholder={intl.formatMessage({ id: 'operations.selectDate' })}
                                 >
                                    <li>
                                       <DateTypeSelector.Range
                                          type="any"
                                          title={intl.formatMessage({ id: 'operations.any' })}
                                       />
                                    </li>
                                    <li>
                                       <DateTypeSelector.Range
                                          type="other"
                                          title={intl.formatMessage({ id: 'operations.other' })}
                                       />
                                    </li>
                                 </DateTypeSelector>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>

               <div className="flex items-center relative  ">
                  {watch('query') && (
                     <FontAwesomeIcon
                        icon={faX}
                        className="absolute right-14 cursor-pointer"
                        onClick={() => {
                           resetField('query', { defaultValue: '' })
                           formButton.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                        }}
                     />
                  )}
                  <Input
                     {...register('query')}
                     className="!rounded-r-none"
                     containerClassName="!m-0"
                     placeholder="ID de operación, nombre, email o ID de socio"
                     width="300px"
                  />
                  <Button bsStyle="gray" type="submit" className="flex items-center !border-l-0 !rounded-l-none">
                     <i className="invisible inline-block max-w-0">.</i>
                     <FontAwesomeIcon icon={faSearch} />
                  </Button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default Filters
