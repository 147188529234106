import Title from '../common/Title'
import { useIntl } from 'react-intl'
import Card from '../common/Card'
import {
   Bar,
   BarChart,
   CartesianGrid,
   Cell,
   Legend,
   Line,
   LineChart,
   ResponsiveContainer,
   Tooltip,
   XAxis,
   YAxis,
} from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { Select } from 'ui/fields'
import { useEffect, useMemo, useState } from 'react'
import { Level } from '../partners'
import { Amount, Table } from 'ui'
import { createDate, createDateFromISO, getFormattedDate, today } from 'utils/dates'
import DateRangeSelector from '../common/DateRangeSelector/DateRangeSelector'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { getCurrentValues } from '../../utils/dashboardUtils'
import { useGetPrograms } from '../../hooks/useGetPrograms'
import { getSummary } from '../../api/summary'
import { useMutation } from '@tanstack/react-query'
import { useAppSelector } from '../../store/hooks'
import useGetMembersMostIncome from '../../hooks/useGetMembersMostIncome'
import useGetMembersMostPoints from '../../hooks/useGetMembersMostPoints'
import useGetMembersMostActivity from '../../hooks/useGetMembersMostActivity'
import useGetMembersLessActivity from '../../hooks/useGetMembersLessActivity'
import ResumeOperations from '../operations/components/ResumeOperations'

export type Summary = {
   loyaltyPlan: {
      id: number
      name: string
      level: Array<Level>
   }
   activations: number
   pointsAccumulated: number
   pointsRedeemed: number
   activationByPeriods: Record<string, number>
   activationsByChannel: Record<string, number>
   membershipPercentage: Record<string, number>
   pointsAccumulatedAndRedeemed: Record<string, { accumulated: number; redeemed: number }>
   membership: Array<{
      name: string
      order: number
      percentage: string
      total: number
   }>
}



type Props = {}

export const Dashboard = (props: Props) => {
   const intl = useIntl()
   const location = useLocation()
   const navigate = useNavigate()
   const { locale, account } = useAppSelector((state) => state.base)
   const [isCleared, setIsCleared] = useState(false)
   const [currentValues, setCurrentValues] =
      useState<{
         loyaltyProgramId: number
         from: string
         to: string
      }>()
   const { data: programList, status } = useGetPrograms()
   const { data: membersMostIncome, isLoading: isLoadingMembersMostIncome } = useGetMembersMostIncome(currentValues?.loyaltyProgramId || 0)
   const { data: membersMostPoints, isLoading: isLoadingMembersMostPoints } = useGetMembersMostPoints(currentValues?.loyaltyProgramId || 0)
   const { data: membersMostActivity, isLoading: isLoadingMembersMostActivity } = useGetMembersMostActivity(currentValues?.loyaltyProgramId || 0)
   const { data: membersLessActivity, isLoading: isLoadingMembersLessActivity } = useGetMembersLessActivity(currentValues?.loyaltyProgramId || 0)

   const {
      data,
      mutate,
      status: statusSumary,
   } = useMutation({
      mutationFn: getSummary,
   })

   useEffect(() => {
      if (status === 'success' && programList.items.length > 0) {
         const listPrograms = programList.items.filter((program) => program.hasPointBenefit && program.isDefault)
         const request = getCurrentValues(location.search, {
            loyaltyProgramId: listPrograms[0].id,
            from: today().minus({ day: 15 }).toFormat('yyyy-MM-dd'),
            to: today().toFormat('yyyy-MM-dd'),
         })
         mutate(request)
         setCurrentValues(request)
      }
   }, [status, location.search, mutate, programList])

   function onSelectChange(value: number) {
      const request = Object.assign({}, currentValues, { loyaltyProgramId: value })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   function onDateChange(from: string, to: string) {
      const request = Object.assign({}, currentValues, { from, to })
      setIsCleared(false)
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }

   const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'green', 'black', 'cyan']

   const getInterval = (dates: Array<string>) => {
      if (dates.length < 2) {
         return 0
      }
      const startDate = dates[0]
      const endDate = dates[dates.length - 1]
      const date1 = createDate(startDate)
      const date2 = createDate(endDate)
      if (!(date1 && date2)) {
         return 0
      }
      const { days } = date2.diff(date1, 'days').toObject()
      if (!days) {
         return 0
      }
      return Math.round(days / 5)
   }
   const columnsIncome = useMemo(() => {
      return [
         {
            header: intl.formatMessage({ id: 'dashboard.table.members.id' }),
            accessorKey: 'member.externalId',
            cell: (value: any) => (
               <Link
                  to={`/${account?.code}/partners/${value.row.original.member.id}/operations`}
                  className="cursor-pointer "
               >
                  {value.row.original.member.externalId}
               </Link>
            ),
         },
         {
            accessorKey: 'member.fullName',
            header: intl.formatMessage({ id: 'dashboard.table.members.name' }),
         },
         {
            accessorKey: 'totalValue',
            header: intl.formatMessage({ id: 'dashboard.table.members.income' }),
            cell: (value: any) => <Amount amount={value.row.original.totalValue} />,
         },
      ]
   }, [account, intl])
   const columnsReservattion = useMemo(() => {
      return [
         {
            header: intl.formatMessage({ id: 'dashboard.table.members.id' }),
            accessorKey: 'member.externalId',
            cell: (value: any) => (
               <Link
                  to={`/${account?.code}/partners/${value.row.original.member.id}/operations`}
                  className="cursor-pointer "
               >
                  {value.row.original.member.externalId}
               </Link>
            ),
         },
         {
            accessorKey: 'member.fullName',
            header: intl.formatMessage({ id: 'dashboard.table.members.name' }),
         },
         {
            accessorKey: 'totalValue',
            header: intl.formatMessage({ id: 'dashboard.table.members.points' }),
            cell: (value: any) => <Amount amount={value.row.original.totalValue} showCurrencySign={false} />,
         },
      ]
   }, [account, intl])

   const columnsMoreActivity = useMemo(() => {
      return [
         {
            header: intl.formatMessage({ id: 'dashboard.table.members.id' }),
            accessorKey: 'member.externalId',
            cell: (value: any) => (
               <Link
                  to={`/${account?.code}/partners/${value.row.original.member.id}/operations`}
                  className="cursor-pointer "
               >
                  {value.row.original.member.externalId}
               </Link>
            ),
         },
         {
            accessorKey: 'member.fullName',
            header: intl.formatMessage({ id: 'dashboard.table.members.name' }),
         },
         {
            accessorKey: 'date',
            header: intl.formatMessage({ id: 'dashboard.table.members.lastActivity' }),
            cell: (value: any) =>
               createDateFromISO(value.row.original.date, { setZone: true })
                  .setLocale(locale)
                  .toFormat('ccc. dd LLL. yy'),
         },
      ]
   }, [account, locale, intl])
   const columnsLessActivity = useMemo(() => {
      return [
         {
            header: intl.formatMessage({ id: 'dashboard.table.members.id' }),
            accessorKey: 'member.externalId',
            cell: (value: any) => (
               <Link
                  to={`/${account?.code}/partners/${value.row.original.member.id}/operations`}
                  className="cursor-pointer "
               >
                  {value.row.original.member.externalId}
               </Link>
            ),
         },
         {
            accessorKey: 'member.fullName',
            header: intl.formatMessage({ id: 'dashboard.table.members.name' }),
         },
         {
            accessorKey: 'date',
            header: intl.formatMessage({ id: 'dashboard.table.members.lastActivity' }),
            cell: (value: any) =>
               createDateFromISO(value.row.original.date, { setZone: true })
                  .setLocale(locale)
                  .toFormat('ccc. dd LLL. yy'),
         },
      ]
   }, [account, locale, intl])
   const columnsMembersPercentage = useMemo(() => {
      return [
         {
            header: intl.formatMessage({ id: 'dashboard.table.members.level' }),
            accessorKey: 'name',


         },
         {
            accessorKey: 'percentage',
            header: intl.formatMessage({ id: 'dashboard.table.members.percentage' }),
         },
         {
            accessorKey: 'total',
            header: intl.formatMessage({ id: 'dashboard.table.members.total' }),

         },
      ]
   }, [intl])


   return (
      <>
         <Title value={intl.formatMessage({ id: 'titles.dashboard' })} />
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               <div className="px-4 mb-6 flex flex-col md:flex-row gap-y-2 justify-between md:items-center">
                  <div className="flex gap-x-2">
                     <Select
                        value={currentValues?.loyaltyProgramId || ''}
                        onChange={onSelectChange}
                        disabled={status === 'pending'}
                        className="min-w-[150px]"
                     >
                        {status === 'pending' && <Select.Option value="">...</Select.Option>}
                        {programList?.items.map((program) => {
                           if (!program.hasPointBenefit) {
                              return null
                           }
                           return (
                              <Select.Option key={program.id} value={program.id}>
                                 {program.name}
                              </Select.Option>
                           )
                        })}
                     </Select>
                  </div>
                  <div>
                     <DateRangeSelector
                        from={currentValues?.from}
                        to={currentValues?.to}
                        reload={isCleared && statusSumary === 'pending'}
                        onChange={onDateChange}
                        onClear={() => {
                           setIsCleared(true)
                           const request = Object.assign({}, currentValues, { from: null, to: null })
                           navigate(
                              `?${qs.stringify(request, {
                                 arrayFormat: 'comma',
                                 allowDots: true,
                                 encode: false,
                              })}`,
                           )
                        }}
                     />
                  </div>
               </div>

               <ResumeOperations
                  request={{
                     loyaltyPlanId: currentValues?.loyaltyProgramId,
                     dates: { from: currentValues?.from, to: currentValues?.to },
                  }}
               />

               <div className="grid md:grid-cols-2 gap-x-4 gap-y-8 px-4">
                  <div className="border rounded">
                     <div className="border-b p-3 font-semibold">
                        {intl.formatMessage({ id: 'dashboard.periodActivations' })}
                     </div>
                     <div className="h-[300px] relative">
                        {statusSumary === 'pending' && (
                           <div className="bg-neutral-100 absolute inset-0 z-10 opacity-50 flex items-center justify-center">
                              <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                           </div>
                        )}
                        {data && (
                           <ResponsiveContainer height={300} className="font-medium">
                              <LineChart
                                 data={Object.keys(data.activationByPeriods).map((key) => ({
                                    name: getFormattedDate(
                                       key,
                                       intl.formatMessage({ id: 'dashboard.dateFormat' }),
                                       locale,
                                       'yyyy-MM-dd',
                                    ),
                                    value: data?.activationByPeriods[key],
                                 }))}
                                 margin={{ top: 20, bottom: 20, right: 40 }}
                              >
                                 <CartesianGrid vertical={false} stroke="#eee" strokeDasharray="5 0" />
                                 <Line
                                    type="linear"
                                    dataKey="value"
                                    name={intl.formatMessage({ id: 'dashboard.activations' })}
                                    stroke="#8884d8"
                                 />
                                 <XAxis dataKey="name" interval={getInterval(Object.keys(data.activationByPeriods))} />
                                 <YAxis axisLine={false} tickLine={false} />
                                 <Tooltip />
                                 <Legend />
                              </LineChart>
                           </ResponsiveContainer>
                        )}
                     </div>
                  </div>
                  <div className="border rounded">
                     <div className="border-b p-3 font-medium">
                        {intl.formatMessage({ id: 'dashboard.apliedAndRedeemedPoints' })}
                     </div>
                     <div className="h-[300px] relative">
                        {statusSumary === 'pending' && (
                           <div className="bg-neutral-100 absolute inset-0 z-10 opacity-50 flex items-center justify-center">
                              <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                           </div>
                        )}
                        {data && (
                           <ResponsiveContainer height={300} className="font-semibold">
                              <LineChart
                                 data={Object.keys(data.pointsAccumulatedAndRedeemed).map((key) => {
                                    const result = data?.pointsAccumulatedAndRedeemed[key]
                                    return {
                                       name: getFormattedDate(
                                          key,
                                          intl.formatMessage({ id: 'dashboard.dateFormat' }),
                                          locale,
                                          'yyyy-MM-dd',
                                       ),
                                       accumulated: result?.accumulated,
                                       redeemed: result?.redeemed,
                                    }
                                 })}
                                 margin={{ top: 20, bottom: 20, right: 40 }}
                              >
                                 <CartesianGrid vertical={false} stroke="#eee" strokeDasharray="5 0" />
                                 <Line
                                    type="linear"
                                    dataKey="accumulated"
                                    stroke="#8884d8"
                                    name={intl.formatMessage({ id: 'dashboard.applications' })}
                                 />
                                 <Line
                                    type="linear"
                                    dataKey="redeemed"
                                    stroke="#82ca9d"
                                    name={intl.formatMessage({ id: 'dashboard.redemptions' })}
                                 />
                                 <XAxis
                                    dataKey="name"
                                    interval={getInterval(Object.keys(data.pointsAccumulatedAndRedeemed))}
                                 />
                                 <YAxis axisLine={false} tickLine={false} />
                                 <Tooltip />
                                 <Legend />
                              </LineChart>
                           </ResponsiveContainer>
                        )}
                     </div>
                  </div>
                  <div className="border rounded">
                     <div className="border-b p-3 font-semibold">
                        {intl.formatMessage({ id: 'dashboard.activationsPerChannel' })}
                     </div>
                     <div className="h-[300px] relative">
                        {statusSumary === 'pending' && (
                           <div className="bg-neutral-100 absolute inset-0 z-10 opacity-50 flex items-center justify-center">
                              <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                           </div>
                        )}
                        {data && (
                           <ResponsiveContainer height={300} className="font-medium">
                              <BarChart
                                 data={Object.keys(data.activationsByChannel).map((key) => ({
                                    name: key,
                                    value: data?.activationsByChannel[key],
                                 }))}
                                 margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 20,
                                 }}
                              >
                                 <CartesianGrid
                                    vertical={false}
                                    horizontal={false}
                                    stroke="#eee"
                                    strokeDasharray="5 0"
                                 />

                                 <XAxis dataKey="name" hide />
                                 <YAxis axisLine={false} tickLine={false} />
                                 <Tooltip />
                                 <Bar dataKey="value" isAnimationActive={false}>
                                    {Object.keys(data.activationsByChannel).map((entry, index) => (
                                       <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                 </Bar>

                                 <Legend
                                    wrapperStyle={{ paddingTop: '20px' }}
                                    payload={[
                                       ...Object.keys(data.activationsByChannel).map(
                                          (entry, index) =>
                                          ({
                                             value: entry,
                                             type: 'square',
                                             id: `ID${index}`,
                                             color: COLORS[index % COLORS.length],
                                          } as Payload),
                                       ),
                                    ]}
                                 />
                              </BarChart>
                           </ResponsiveContainer>
                        )}
                     </div>
                  </div>
                  <div className="border rounded">
                     <div className="border-b p-3 font-semibold">
                        {intl.formatMessage({ id: 'dashboard.membershipsPerLevel' })}
                     </div>
                     <div className="h-[300px] relative">
                        <Table
                           fetching={statusSumary === 'pending'}
                           data={data?.membership?.filter(i => i.order) || []}
                           columns={columnsMembersPercentage}
                           tableClassName="border-b"
                        />
                     </div>
                  </div>
                  <div>
                     <div className="border rounded">
                        <div className="border-b p-3 font-semibold">
                           {intl.formatMessage({ id: 'dashboard.table.members.tittle.mostIncome' })}
                        </div>
                        <Table
                           fetching={isLoadingMembersMostIncome}
                           data={membersMostIncome ?? []}
                           columns={columnsIncome}
                           tableClassName="border-b"
                        />
                        <div className="py-3">
                           <Link
                              to={`/${account?.code}/partners?loyaltyPlanIds=${currentValues?.loyaltyProgramId}&active=Activos&sort=totalRevenue,desc`}
                              className="px-4 text-orange-600 cursor-pointer hover:underline"
                           >
                              {intl.formatMessage({ id: 'dashboard.table.members.showAll' })}
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div>
                     <div className="border rounded">
                        <div className="border-b p-3 font-semibold">
                           {intl.formatMessage({ id: 'dashboard.table.members.tittle.points' })}
                        </div>
                        <Table
                           fetching={isLoadingMembersMostPoints}
                           data={membersMostPoints ?? []}
                           columns={columnsReservattion}
                           tableClassName="border-b"
                        />
                        <div className="py-3">
                           <Link
                              to={`/${account?.code}/partners?loyaltyPlanIds=${currentValues?.loyaltyProgramId}&active=Activos&sort=points,desc`}
                              className="px-4 text-orange-600 cursor-pointer hover:underline"
                           >
                              {intl.formatMessage({ id: 'dashboard.table.members.showAll' })}
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div>
                     <div className="border rounded">
                        <div className="border-b p-3 font-semibold">
                           {intl.formatMessage({ id: 'dashboard.table.members.tittle.most.Activity' })}
                        </div>
                        <Table
                           fetching={isLoadingMembersMostActivity}
                           data={membersMostActivity ?? []}
                           columns={columnsMoreActivity}
                           tableClassName="border-b"
                        />
                        <div className="py-3">
                           <Link
                              to={`/${account?.code}/partners?loyaltyPlanIds=${currentValues?.loyaltyProgramId}&active=Activos&sort=lastOperationDate,desc`}
                              className="px-4 text-orange-600 cursor-pointer hover:underline"
                           >
                              {intl.formatMessage({ id: 'dashboard.table.members.showAll' })}
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div>
                     <div className="border rounded">
                        <div className="border-b p-3 font-semibold">
                           {intl.formatMessage({ id: 'dashboard.table.members.tittle.less.Activity' })}
                        </div>
                        <Table
                           fetching={isLoadingMembersLessActivity}
                           data={membersLessActivity ?? []}
                           columns={columnsLessActivity}
                           tableClassName="border-b"
                        />
                        <div className="py-3">
                           <Link
                              to={`/${account?.code}/partners?loyaltyPlanIds=${currentValues?.loyaltyProgramId}&active=Activos&sort=lastOperationDate,asc`}
                              className="px-4 text-orange-600 cursor-pointer hover:underline"
                           >
                              {intl.formatMessage({ id: 'dashboard.table.members.showAll' })}
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </Card>
         </div>
      </>
   )
}
