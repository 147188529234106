import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getMembersMostActivity } from '../../api/summary'

function useGetMembersMostActivity(id?: number) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_MEMBERS_MOST_ACTIVITY],
      queryFn: () => getMembersMostActivity(id),
      enabled: !!id,
   })
}
export { useGetMembersMostActivity }
export default useGetMembersMostActivity
