import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'ui'
import { useGetPartner } from '../../hooks/useGetPartner'
import History from './components/ActivityHistory'
import Card from '../common/Card'
import CardItem from './components/common/CardItem'
import { DateTime } from 'luxon'
import { useMutation } from '@tanstack/react-query'
import { sendConfirmation, chnageStatusPartner } from '../../api/profile'
import { toast } from 'react-toastify'
import PartnerTemplate from './components/PartnerTemplate'
import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu'
import useGetPartnerHistory from '../../hooks/useGetPartnerHistory'
import { useAppSelector } from '../../store/hooks'

type Props = {}

const Partner = (props: Props) => {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const { data: partner, status: partnerStatus, refetch } = useGetPartner(id)
   const { refetch: refetchHistory } = useGetPartnerHistory({ memberId: id, size: 10, page: 0 })
   const { data: acountProfile } = useAppSelector(state => state.profile)
   const [isOpen, setIsOpen] = useState(false)
   const [isOpenDeactivateModal, setIsOpenDeactivateModal] = useState(false)
   const [isAgree, setIsAgree] = useState(false)
   const [reason, setReason] = useState('')
   const { mutate } = useMutation({
      mutationFn: sendConfirmation,
   })
   const { mutate: deactivatePartnerFunc, isPending } = useMutation({
      mutationFn: chnageStatusPartner,
   })
   function sendConfimationEvent() {
      if (id) {
         mutate(id, {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: 'partner.confirmation.success' }))
               setIsOpen(false)
               refetch()

            },
            onError: () => {
               toast.success(intl.formatMessage({ id: 'partner.confirmation.fail' }))
            },
         })
      }
   }
   function sendDeactivateEvent() {
      if (id && partner) {
         deactivatePartnerFunc({ memberId: id, note: reason, externalId: partner.externalId }, {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: `partner.${partner?.isActive ? 'deactivate' : 'activate'}.success` }))
               setIsOpenDeactivateModal(false)
               setIsAgree(false)
               setReason('')
               refetch()
               refetchHistory()
            },
            onError: () => {
               toast.error(intl.formatMessage({ id: 'partner.status.fail' }))
               setIsOpenDeactivateModal(false)
               setIsAgree(false)
               setReason('')
            },
         })
      }
   }

   const cancelModalStatus = () => {
      setIsOpenDeactivateModal(false)
      setIsAgree(false)
      setReason('')
   }
   const isDisabled =
      (partner?.isActive && !isAgree) ||
      (!partner?.isActive && reason.length === 0) ||
      isPending;
   const isAdmin = acountProfile?.roles.some((role) => role.code === 'LOYALTY_ADMIN')
   return (
      <>
         <PartnerTemplate
            partner={partner}
            partnerStatus={partnerStatus}
            accountCode={accountCode}
            id={id}
            activeTabCode="info"
         >
            <div className="flex flex-col md:flex-row gap-x-4 mx-4">
               <div className="basis-8/12">
                  <Card>
                     <Card.Title>
                        <div className="flex justify-between gap-x-2  items-center">
                           {intl.formatMessage({ id: 'partner.information' })}
                           <Menu
                              menuButton={
                                 <MenuButton className="w-[30px] h-[30px] rounded-full hover:bg-gray-100">
                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                 </MenuButton>
                              }
                              transition
                              align="end"
                           >
                              <MenuItem>
                                 <Link
                                    to={`/${accountCode}/partners/${id}/edit`}
                                    className=""
                                 >
                                    {intl.formatMessage({ id: 'partner.edit' })}
                                 </Link>
                              </MenuItem>
                              <MenuItem>
                                 <p className="" onClick={() => {
                                    setIsOpen(true)
                                 }}>
                                    {intl.formatMessage({ id: 'partner.confirmation.send' })}
                                 </p>
                              </MenuItem>
                              {partner &&
                                 isAdmin &&
                                 <>
                                    <MenuDivider />
                                    <MenuItem>
                                       <p className={`${partner.isActive ? 'text-red-500' : ''}`} onClick={() => {
                                          setIsOpenDeactivateModal(true)
                                       }}>
                                          {intl.formatMessage({ id: `partner.${partner.isActive ? 'deactivate' : 'activate'}.tittle` })}
                                       </p>
                                    </MenuItem>
                                 </>
                              }
                           </Menu>
                        </div>
                     </Card.Title>
                     <div className="px-4 pt-3 pb-6 relative">
                        <div className="grid grid-cols-2 gap-4">
                           <CardItem label={intl.formatMessage({ id: 'partner.name' })} value={partner?.firstName} />
                           <CardItem label={intl.formatMessage({ id: 'partner.lastName' })} value={partner?.lastName} />
                           <CardItem label={intl.formatMessage({ id: 'partner.email' })} value={partner?.email} />
                           <CardItem label={intl.formatMessage({ id: 'partner.phone' })} value={partner?.phoneNumber} />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.status' })}
                              {...(partner &&
                                 partner.maritalStatus && {
                                 value: intl.formatMessage({ id: `partner.status.${partner.maritalStatus}` }),
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.birthday' })}
                              value={partner?.dateOfBirth}
                           />
                           {
                              <CardItem
                                 label={intl.formatMessage({ id: 'partner.sex' })}
                                 {...(partner &&
                                    partner?.gender && {
                                    value: intl.formatMessage({ id: `partner.sex.${partner.gender}` }),
                                 })}
                              />
                           }

                           <CardItem
                              label={intl.formatMessage({ id: 'partner.country' })}
                              value={partner?.countryOfResidence}
                           />
                           {partnerStatus === 'pending' ? (
                              <div className="absolute inset-0 bg-neutral-100 opacity-25 flex items-center justify-center">
                                 <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                              </div>
                           ) : null}
                        </div>
                     </div>
                  </Card>
                  {partner?.loyaltyPlans && partner?.loyaltyPlans.length > 0 && (
                     <Card>
                        <Card.Title>{intl.formatMessage({ id: 'partner.membership.information' })}</Card.Title>
                        {partner?.loyaltyPlans.map((l, index) => (
                           <React.Fragment key={index}>
                              <h3 className="px-4 pt-4 font-semibold">{l.loyaltyPlanName}</h3>
                              <div
                                 className={`px-4 pt-3 pb-6 relative ${index < partner.loyaltyPlans.length - 1 ? 'border-b' : ''
                                    }`}
                              >
                                 <div className="grid grid-cols-2 gap-4">
                                    <CardItem
                                       label={intl.formatMessage({ id: 'partner.points' })}
                                       value={l.points.toString()}
                                    />
                                    <CardItem
                                       label={intl.formatMessage({ id: 'partner.registrationChannel' })}
                                       value={l?.registrationChannel}
                                    />
                                    <CardItem
                                       label={intl.formatMessage({ id: 'partner.registrationDate' })}
                                       value={DateTime.fromISO(l.registrationDate).toFormat('yyyy-MM-dd')}
                                    />
                                    <CardItem label={intl.formatMessage({ id: 'partner.level' })} value={l.level} />
                                 </div>
                              </div>
                           </React.Fragment>
                        ))}
                     </Card>
                  )}
                  <Card>
                     <Card.Title>{intl.formatMessage({ id: 'partner.title.additional' })}</Card.Title>
                     <div className="px-4 pt-3 pb-6 relative">
                        <div className="grid grid-cols-2 gap-4">
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.state' })}
                              {...(partner &&
                                 partner?.state && {
                                 value: partner.state,
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.swimming' })}
                              {...(partner &&
                                 partner?.swimmingSkill && {
                                 value: partner.swimmingSkill ? 'Sí' : 'No',
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.phonenumber' })}
                              {...(partner &&
                                 partner?.phoneNumber && {
                                 value: partner.phoneNumber,
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.typeBlood' })}
                              {...(partner &&
                                 partner?.bloodType && {
                                 value: partner.bloodType,
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.allergies' })}
                              {...(partner &&
                                 partner?.allergies && {
                                 value: partner.allergies,
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.passion' })}
                              {...(partner &&
                                 partner?.passion && {
                                 value: partner.passion,
                              })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.form.pms.perfils' })}
                              {...(partner?.profiles?.length
                                 ? {
                                    value: (
                                       <ul>
                                          {partner?.profiles.map((profile) => (
                                             <li key={profile.id} >
                                                {profile.system} : {profile.id}
                                             </li>
                                          ))}
                                       </ul>
                                    ),
                                 }
                                 : {})}
                           />

                        </div>
                     </div>
                  </Card>
               </div>
               <div className="basis-4/12">
                  <History idPlan={partner?.externalId} />
               </div>
            </div>
            <Modal visible={isOpen} onClose={() => setIsOpen(false)} size="md">
               <Modal.Header>
                  <h2 className="px-4">{intl.formatMessage({ id: 'partner.confirmation.send' })}</h2>
               </Modal.Header>
               <Modal.Body>
                  {partner && (
                     <div className="px-4">
                        <div className="flex items-center gap-x-2">
                           <FontAwesomeIcon icon={faEnvelope} className="text-neutral-500 text-xl" />
                           <div className="leading-tight">
                              <div className="text-neutral-400">{intl.formatMessage({ id: 'partner.email' })}</div>
                              <div>{partner.email}</div>
                           </div>
                        </div>
                     </div>
                  )}
                  <hr className="my-4" />
                  <div className="p-4">{intl.formatMessage({ id: 'partner.confirmation.information' })}</div>
               </Modal.Body>
               <Modal.Footer>
                  <div className="flex justify-end px-4 gap-x-4">
                     <Button bsStyle="success" onClick={sendConfimationEvent}>
                        {intl.formatMessage({ id: 'partner.confirmation.confirm' })}
                     </Button>
                     <Button onClick={() => setIsOpen(false)}>
                        {intl.formatMessage({ id: 'partner.confirmation.cancel' })}
                     </Button>
                  </div>
               </Modal.Footer>
            </Modal>
            <Modal visible={isOpenDeactivateModal} onClose={cancelModalStatus} size="md" >
               <Modal.Header>
                  <h2 className="px-4 text-lg font-bold"> {intl.formatMessage({ id: `partner.${partner?.isActive ? 'deactivate' : 'activate'}.tittle` })}</h2>
               </Modal.Header>
               <Modal.Body className='p-8'>
                  <div className=" text-lg text-gray-600">
                     <p>
                        {intl.formatMessage({ id: `partner.${partner?.isActive ? 'deactivate' : 'activate'}.alert1` }, {
                           name: <span className={isAgree ? 'font-bold' : ''}>{`${partner?.firstName || '' + partner?.lastName}`}</span>,
                           id: <span className={isAgree ? 'font-bold' : ''}>{partner?.externalId}</span>
                        })}
                     </p>
                     <br />
                     {partner?.isActive ? <>
                        <ul className='grid justify-center space-y-2 px-4 pb-4 list-disc '>
                           <li className='text-left rounded'>{intl.formatMessage({ id: `partner.desactivate.list-alert1` })}</li>
                           <li className='text-left rounded'>{intl.formatMessage({ id: `partner.desactivate.list-alert2` })}</li>
                           <li className='text-left rounded'>{intl.formatMessage({ id: `partner.desactivate.list-alert3` })}</li>
                           <li className='text-left rounded'>{intl.formatMessage({ id: `partner.desactivate.list-alert4` })}</li>
                        </ul>
                        <label htmlFor="agreed">
                           <input type="checkbox" id='agreed' onClick={() => setIsAgree(!isAgree)} /> {' '}
                           {intl.formatMessage({ id: `partner.desactivate.list-alert5` })}
                        </label>
                     </> :
                        <>
                           <label className='block mb-2 font-bold' htmlFor="reason">{intl.formatMessage({ id: 'partner.reaon.activate' })}</label>
                           <textarea
                              onChange={(e) => {
                                 setIsAgree(true)
                                 setReason(e.target.value?.trim())
                              }}
                              name="reason"
                              id="reason"
                              className='w-full h-24 border border-gray-300 rounded p-2'
                              maxLength={200}
                           />
                           <small >{intl.formatMessage({ id: 'partner.reaon.note' })}</small>
                        </>}
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <div className="flex justify-end px-4 gap-x-4">
                     <Button bsStyle="success" onClick={sendDeactivateEvent}
                        disabled={isDisabled}
                     >
                        {intl.formatMessage({ id: `partner.confirmation.${partner?.isActive ? 'deactivate' : 'activate'}` })}
                     </Button>
                     <Button
                        disabled={isPending}
                        onClick={cancelModalStatus}>
                        {intl.formatMessage({ id: 'partner.confirmation.cancel' })}
                     </Button>
                  </div>
               </Modal.Footer>
            </Modal>
         </PartnerTemplate>
      </>
   )
}

export default Partner
