import classNames from 'classnames'
import React from 'react'
import Breadcrumb, { type BreadcrumbItems} from './Breadcrumb'

type Props = {
   value?: string
   className?: string
   children?: React.ReactNode
   breadcrumb?: BreadcrumbItems
}

const Title = ({ value, className, children, breadcrumb }: Props) => {
   return (
      <div className={classNames('bg-white border-b -mt-16 pt-16 mb-4', className)}>
         {(breadcrumb?.length ?? 0) > 0 ? <Breadcrumb className="p-4 pb-0" items={breadcrumb!} /> : null}
         {value && <h1 className="text-2xl p-4">{value}</h1>}
         {children}
      </div>
   )
}

export default Title
