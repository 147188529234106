import React from 'react'
import { useGetOperationList } from '../../../hooks/useGetOperationList'
import { Table } from 'ui'
type Props = {
   currentValues: any
   onPageChange: (p: any) => any
   onSortChange: (p: any) => any
   columns: any
   children?: React.ReactNode
   defaultProgram?: number
   defaultQuery?: string
   contanierClassName?: string
}
export default function TableOperations(props: Props) {
   const { currentValues, onPageChange, columns, onSortChange, children, defaultQuery, contanierClassName } = props
const { sort }=currentValues
   const { data: operationList, status } = useGetOperationList({
      ...currentValues,
      ...(defaultQuery && {
         query: defaultQuery,
      }),
   })
   return (
      <>
         <Table
            fetching={status === 'pending'}
            columns={columns}
            data={operationList?.items ?? []}
            containerClassName={contanierClassName}
            tableClassName="border-y"
            footerClassName="px-4"
            showTableResume
            pagination={{
               defaultPageSize: currentValues.size,
               defaultPageIndex: currentValues.page,
               totalSize: operationList?.totalSize ?? 0,
               onPageChange: onPageChange,
            }}
            sorting={{
               sorting:
                  sort && sort.length
                     ? [{ id: sort?.split(',')[0], desc: sort?.split(',')[1] === 'desc'}]
                     : null,
               onSortChange: onSortChange,
            }}
         >
            {children}
         </Table>
      </>
   )
}
