import React from 'react'
import { useLocation } from 'react-router-dom'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import qs from 'qs'
import { Button, Dropdown } from 'ui'
import { Input } from 'ui/fields'
import MultiCheck from 'ui/MultiCheck/MultiCheck'
import { useGetChannelList } from '../../../hooks/useGetChannelList'
import { useGetLevelList } from '../../../hooks/useGetLevelList'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'

export type PartnerFilterForm = {
   query: string
   loyaltyPlanIds: number | string
   channelIds: Array<number>
   levelIds: Array<number>
   active?: string
}

type Props = {
   className?: string
   onSubmit: (data: PartnerFilterForm) => void
   defaultValues?: PartnerFilterForm
   defaultProgram?: number
}

const Filters = ({ className, onSubmit, defaultValues, defaultProgram }: Props) => {
   const { data: programs } = useGetProgramFilter()
   const { handleSubmit, register, watch, control, resetField } = useForm<PartnerFilterForm>({
      defaultValues: {
         query: defaultValues?.query || '',
         loyaltyPlanIds: defaultValues?.loyaltyPlanIds,
         channelIds: defaultValues?.channelIds || [],
         levelIds: defaultValues?.levelIds || [],
         active: defaultValues?.active || 'Todos',
      },
      mode: 'onChange',
   })

   const intl = useIntl()
   const formButton = React.useRef<HTMLFormElement>(null)
   const location = useLocation()
   const planIds = qs.parse(location.search, { comma: false, ignoreQueryPrefix: true }) as { loyaltyPlanIds?: number }
   const { loyaltyPlanIds } = planIds
   const { data: channels, status: channelsStatus } = useGetChannelList()

   const { data: levels, status: levelsStatus } = useGetLevelList(
      planIds && loyaltyPlanIds
         ? { loyaltyPlanIds: loyaltyPlanIds as unknown as number }
         : { loyaltyPlanIds: defaultValues?.loyaltyPlanIds as unknown as number },
   )
   let programId = useWatch({ control, name: 'loyaltyPlanIds' })
   const planLoyalty = programs?.items.find((program) => {
      return typeof programId === 'string' ? program.id === parseInt(programId) : program.id === programId
   })?.name
   const onSubmitEvent = (data: PartnerFilterForm) => {
      const { query, ...rest } = data
      const result = { ...rest } as PartnerFilterForm
      if (query && query.trim() !== '') {
         result.query = query.trim()
         result.query = encodeURIComponent(result.query)
      }

      onSubmit(result)
   }

   const drawCount = (sizeSelected: number, total: number, fetching: boolean, word = 'Todas') => {
      if (fetching) {
         return '...'
      }
      if (sizeSelected > 0) {
         return `${sizeSelected} de ${total}`
      }
      return word
   }
   return (
      <div className={classNames(className, 'mb-6')}>
         <form onSubmit={handleSubmit(onSubmitEvent)} ref={formButton}>
            <div className="flex flex-col-reverse md:flex-row gap-y-2 justify-between md:items-center">
               <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                        {intl.formatMessage({ id: 'partners.status' })}: {watch('active')}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="active"
                              render={({ field: { ref, value, onChange } }) => (
                                 <>
                                    {['Activos', 'Inactivos', 'Todos'].map((option) => {
                                       return (
                                          <li
                                             key={option}
                                             className="hover:bg-gray-100 focus:outline-none cursor-pointer block  whitespace-nowrap font-light p-2"
                                             onClick={({ currentTarget }) => {
                                                onChange(option)
                                                formButton.current?.click()
                                             }}
                                          >
                                             {option}
                                          </li>
                                       )
                                    })}
                                 </>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                        {intl.formatMessage({ id: 'partners.program' })}: {planLoyalty}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="loyaltyPlanIds"
                              render={({ field: { ref, onChange, ...rest } }) => (
                                 <>
                                    {programs?.items?.map((option) => {
                                       if (!option.hasPointBenefit) {
                                          return null
                                       }
                                       return (
                                          <li
                                             key={option.id}
                                             className="hover:bg-gray-100 focus:outline-none cursor-pointer block  whitespace-nowrap font-light p-2"
                                             onClick={({ currentTarget }) => {
                                                onChange(option.id)
                                                formButton.current?.click()
                                             }}
                                          >
                                             {option.name}
                                          </li>
                                       )
                                    })}
                                 </>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                        {intl.formatMessage({ id: 'partners.level' })}:{' '}
                        {drawCount(
                           watch('levelIds')?.length,
                           levels?.items?.length ?? 0,
                           levelsStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="levelIds"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {levels?.items?.map((option) => (
                                       <li key={option.id} className="hover:bg-gray-100">
                                          <MultiCheck.Check key={option.id} value={option.id} label={option.name} />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {intl.formatMessage({ id: 'partners.channel' })}:{' '}
                        {drawCount(
                           watch('channelIds')?.length,
                           channels?.items?.length ?? 0,
                           channelsStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="channelIds"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {channels?.items.map((option) => (
                                       <li key={option.id} className="hover:bg-gray-100">
                                          <MultiCheck.Check value={option.id} label={option.name} />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>
               <div className="flex items-center relative truncate">
                  {watch('query') && (
                     <FontAwesomeIcon
                        icon={faX}
                        className="absolute right-14 cursor-pointer"
                        onClick={() => {
                           resetField('query', { defaultValue: '' })
                           formButton.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                        }}
                     />
                  )}
                  <Input
                     {...register('query')}
                     className="!rounded-r-none text-xs "
                     containerClassName=" !m-0"
                     placeholder="Nombre, email o ID de socio"
                     width="200px"
                  />
                  <Button bsStyle="gray" type="submit" className="flex items-center !border-l-0 !rounded-l-none">
                     <i className="invisible inline-block max-w-0">.</i>
                     <FontAwesomeIcon icon={faSearch} />
                  </Button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default Filters
