import { Profile } from '../components/user/Login'
import { apiCall } from '../utils/api'

export async function getProfile(): Promise<Profile | null> {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/users/current`,
         method: 'GET',
      })

      return result.data
   } catch (err) {
      return null
   }
}

export async function sendConfirmation(memberId: string): Promise<void> {
   try {
      await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/send-email/welcome/${memberId}`,
         method: 'POST',
      })
   } catch (err) {
      throw err
   }
}
export async function chnageStatusPartner(request:{memberId: string, externalId:number, note?:string}): Promise<void> {
   const {memberId, note, externalId} = request
   try {
      await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/${memberId}/${note?'activate':'deactivate'}?externalId=${externalId}`,
         ...(note?{data:{note}}:{}),
         method: 'PATCH',
      })
   } catch (err) {
      throw err
   }
}